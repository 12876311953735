import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const BlogItem = ({ blogitem }) => {
  return (
    <div className="col-3">
      <div className="blog-wrapper">
        <Img fluid={blogitem.image.fluid} />
      </div>
      <div className="blog-description">
        <h3>{blogitem.title}</h3>
        <p>{blogitem.description.description}</p>
        <Link to={`noticias/${blogitem.slug}`} className="button">
          Leer más
        </Link>
      </div>
    </div>
  )
}

const BlogComponent = () => {
  const blogs = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 9, sort: { order: DESC, fields: createdAt }) {
        nodes {
          id
          title
          slug
          description {
            description
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  const { allContentfulBlog } = blogs
  return (
    <>
      <section name="noticias" className="blog">
        <div className="container">
          <div className="row">
            <div className="info">
              <h1>Noticias</h1>
            </div>
          </div>
          <div className="row">
            {allContentfulBlog.nodes.map(blogitem => (
              <BlogItem blogitem={blogitem} key={blogitem.id} />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogComponent
