import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Clients = () => {
  const clientsLogos = useStaticQuery(graphql`
    query MyQuery {
      allContentfulClient {
        nodes {
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const clients = clientsLogos.allContentfulClient.nodes
  console.log(clients)
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        maxWidth: 960,
        margin: "0 auto",
        gap: "1rem",
      }}
    >
      {clients.map(logo => (
        <div
          style={{
            padding: "1rem",
            background: "white",
            borderRadius: "16px",
            paddingBottom: "56.25%",
            position: "relative",
          }}
        >
          <Img
            fluid={logo.image.fluid}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            imgStyle={{
              objectFit: "contain",
              padding: "1rem",
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default Clients
